@media (min-width: 1200px) {
  .container {
    max-width: 1500px;
  }
}

.loadingspinner {
  pointer-events: none;
  width: 2.5em;
  height: 2.5em;
  border: 0.4em solid transparent;
  border-color: #eee;
  border-top-color: #3e67ec;
  border-radius: 50%;
  animation: loadingspin 1s linear infinite;
}
.loadingspinner {
  position: absolute;
  pointer-events: none;
  width: 2.5em;
  height: 2.5em;
  border: 0.4em solid transparent;
  border-color: #eee;
  border-top-color: #3e67ec;
  border-radius: 50%;
  animation: loadingspin 1s linear infinite;
  left: 50%;
  top: 50%;
}
@keyframes loadingspin {
  100% {
    transform: rotate(360deg);
  }
}

.request-link:hover {
  color: #0275d8 !important;
}

.button {
  display: inline-block;
  margin: 4px 2px;
  background-color: #444;
  font-size: 14px;
  padding-left: 32px;
  padding-right: 32px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button:hover {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;
  color: black;
}

.search-container {
  position: relative;
  display: inline-block;
  margin: 0px;
  height: 50px;
  width: 50px;
  vertical-align: bottom;
}

.mglass {
  display: inline-block;
  pointer-events: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.searchbutton {
  position: absolute;
  font-size: 30px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  color: #000000;
}

.search:focus + .searchbutton {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;
  color: black;
}

.search {
  position: absolute;
  left: 49px;
  background-color: white;
  outline: none;
  border: none;
  padding: 0;
  width: 0;
  height: 100%;
  z-index: 10;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
}

.activeSearch {
  width: 1155px;
  padding: 0 10px 0 15px;
}

.expandright {
  left: auto;
  right: 49px; /* Button width-1px */
}

.expandright:focus {
  padding: 0 0 0 16px;
}

.avatar {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
